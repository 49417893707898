<script>
  const year = new Date().getFullYear();
</script>

<main>
  <h1>What is Food?</h1>

  <article>
    <h2>food <small>noun</small></h2>
    <ol>
      <li>
        Carbon-based compounds regularly inserted into humans, who have a tough
        time deciding which ones to insert.
      </li>
      <li>An app made by David Buckley to solve the above dilemma.</li>
    </ol>
  </article>

  <p>
    &copy; {year} -
    <a href="https://davidjaybuckley.com/developer">davidjaybuckley</a>
  </p>

  <a href="https://www.buymeacoffee.com/dbuckley" target="_blank"
    ><img
      src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
      alt="Buy Me A Coffee"
      style="height: 36px !important;width: 130.2px !important;"
    /></a
  >

  <script
    type="text/javascript"
    src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js"
    data-name="bmc-button"
    data-slug="dbuckley"
    data-color="#FFDD00"
    data-emoji="🍦"
    data-font="Cookie"
    data-text="Buy me an ice cream"
    data-outline-color="#000000"
    data-font-color="#000000"
    data-coffee-color="#ffffff"></script>
</main>

<style>
  article {
    text-align: start;
  }

  h2 {
    text-transform: none;
    margin-bottom: 0.25em;
  }

  h2 small {
    font-size: 0.5em;
  }

  ol {
    padding-left: 0.5em;
    margin-bottom: 2em;
    font-family: "Crimson Text", serif;
  }

  ol li {
    margin-bottom: 0.5em;
  }
</style>
